<template>
  <div id="mains">
    <div class="title">
      <dv-decoration-3
        :reverse="true"
        style="width: 100px; height: 30px"
      />主要数据指标<dv-decoration-3 style="width: 100px; height: 30px" />
    </div>
    <div class="zipCount">
      <!-- <span>总数</span> -->
      <p><count-to
              :start-val="0"
              :end-val="count.number[0]"
              :duration="5000"
              class="fontG"
          /></p>
    </div>
    <div class="content">
      <div class="grid" v-for="(item, index) in datas" :key="index">
        <dv-border-box-8 :reverse="(index%2==0)">
          <span>{{ item.title }}</span>
          <span v-if="item.title=='系统文件存储大小数'"> <count-to
              :start-val="0"
              :end-val="item.data"
              :duration="5000"
              class="fontG"
          />GB</span>
          <span v-else> <count-to
              :start-val="0"
              :end-val="item.data"
              :duration="5000"
              class="fontG"
          /></span>
        </dv-border-box-8>
      </div>
    </div>
  </div>
</template>

<script>
import CountTo from "vue-count-to";
export default {
  components: { CountTo },
  data() {
    return {
      datas: [
        { title: "共享数", data: 37383 },
        { title: "文档数", data: 78383 },
        { title: "申请数", data: 1434 },
        { title:'授权数',data:588 },
        { title:'用户数',data:378 },
        { title:'关键词总数',data:26 },
        { title:'预警总数',data:288 },
        { title:'未处理预警数',data:98 }
      ],
      setIntervalCount:null,
      count:{
        number:[1000],
        content: '{nt}个',
        animationFrame:[6]
      }
    };
  },
  created() {},
  mounted() {
    if(this.setIntervalCount){
      clearInterval(this.setIntervalCount)
    }
    this.init()
    // this.setIntervalCount = setInterval(()=>{
    //   let count = 0
    //   count++
    //   this.init(count);
    // },10000)
  },
  methods: {
    init(count) {
      let sum = 0
      this.datas.forEach(item=>{
        console.log(item.title=='系统文件存储大小数')
        // if(!item.title=='系统文件存储大小数'){
          item.data++
          sum+=item.data
        // }
      })
      console.log(sum)
      this.count.number[0] = [sum]
    },
  },
  destroyed(){
    clearInterval(this.setIntervalCount)
  }
};
</script>

<style scoped lang="less">
#mains {
  width: 100%;
  height: 100%;
  .title {
    color: #06f1ef;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    white-space: nowrap;
  }
  .zipCount {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #06f1ef;
    font-size: 28px;
    font-weight: bold;
    height: 40px;
    p {
      display: flex;
      margin-left: 10px;
      .dv-digital-flop,.fontG {
        margin: 0 5px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 28px;
      }
    }
  }
  .content {
    height: calc(100% - 70px);
    // height: 20%;
    width: 100%;
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    // grid-template-rows: 32% 32% 32%;
    margin: auto;
    grid-gap: 5px;
    .grid {
      text-align: center;
      color: #06f1ef;
      ::v-deep.border-box-content {
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-evenly !important;
        padding: 5px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
