<template>
  <div style="width: 100%;height:100%;margin-top: 30px;border-radius: 10px;">
    <svg style="background-color: black" width="100%" height="100%">
      <a class="fontA"  v-for="(tag, index) in tags" :key="`tag-${index}`">
        <text
          :id="tag.id"
          :x="tag.x"
          :y="tag.y"
          :font-size="20 * (600 / (600 - tag.z))"
          :fill-opacity="(400 + tag.z) / 600"
          @mousemove="listenerMove($event)"
          @mouseout="listenerOut($event)"
          @click="clickToPage"
        >
          {{ tag.text }}
          <a href="javascript:;">{{ tag.value }}个</a>
        </text>
      </a>
    </svg>
  </div>
</template>

<script>
export default {
  name: "word-cloud",
  //数据，宽，高，半径，半径一般位宽高的一半。
  // props: ["data", "width", "height", "RADIUS"],
  data() {
    return {
      width: 300, //svg宽度
      height: 150, //svg高度
      tagsNum: 0, //标签数量
      RADIUS: 100, //球的半径
      speedX: Math.PI / 360 / 1.5, //球一帧绕x轴旋转的角度
      speedY: Math.PI / 360 / 1.5, //球-帧绕y轴旋转的角度
      tags: [],
      data: [{
        name:"计算机",
        value:'160'
      },{
        name:"财务",
        value:'100'
      },{
        name:"表格",
        value:'140'
      },{
        name:"手册",
        value:'200'
      },{
        name:"文档",
        value:'560'
      },{
        name:"解决方案",
        value:'160'
      },{
        name:"管控",
        value:'1000'
      },{
        name:"电子",
        value:'160'
      },{
        name:"公司",
        value:'300'
      },{
        name:"数据共享",
        value:'110'
      },{
        name:"设计",
        value:'1160'
      },{
        name:"统一管理",
        value:'130'
      },{
        name:"数字化",
        value:'160'
      },{
        name:"文件保护",
        value:'100'
      },{
        name:"服务器",
        value:'190'
      },{
        name:"白皮书",
        value:'150'
      }],
      timer: null,
    };
  },
  props:{
    pieList:{
      type:Array,
      default(){
        return []
      }
    }
  },
  computed: {
    CX() {
      //球心x坐标
      return this.width / 2;
    },
    CY() {
      //球心y坐标
      return this.height / 2;
    },
  },
  created() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData() {
      //初始化标签位置
      let tags = [];
      this.tagsNum = this.data.length;
      for (let i = 0; i < this.data.length; i++) {
        let tag = {};
        let k = -1 + (2 * (i + 1) - 1) / this.tagsNum;
        let a = Math.acos(k);
        let b = a * Math.sqrt(this.tagsNum * Math.PI); //计算标签相对于球心的角度
        tag.text = this.data[i].name;
        tag.value = this.data[i].value;
        tag.x = this.CX + this.RADIUS * Math.sin(a) * Math.cos(b); //根据标签角度求出标签的x,y,z坐标
        tag.y = this.CY + this.RADIUS * Math.sin(a) * Math.sin(b);
        tag.z = this.RADIUS * Math.cos(a);
        tag.id = i; // 给标签添加id
        tags.push(tag);
        // console.log(tag);
      }
      this.tags = tags; //让vue替我们完成视图更新
    },
    // 纵向旋转
    rotateX(angleX) {
      var cos = Math.cos(angleX);
      var sin = Math.sin(angleX);
      for (let tag of this.tags) {
        var y1 = (tag.y - this.CY) * cos - tag.z * sin + this.CY;
        var z1 = tag.z * cos + (tag.y - this.CY) * sin;
        tag.y = y1;
        tag.z = z1;
      }
    },
    // 横向旋转
    rotateY(angleY) {
      var cos = Math.cos(angleY);
      var sin = Math.sin(angleY);
      for (let tag of this.tags) {
        var x1 = (tag.x - this.CX) * cos - tag.z * sin + this.CX;
        var z1 = tag.z * cos + (tag.x - this.CX) * sin;
        tag.x = x1;
        tag.z = z1;
      }
    },
    // 运动函数
    runTags() {
      if (typeof this.timer === "number") {
        clearInterval(this.timer);
        this.timer = null;
      }
      let timer = setInterval(() => {
        this.rotateX(this.speedX);
        this.rotateY(this.speedY);
      }, 20);
      this.timer = timer;
    },
    // 监听移入事件
    listenerMove(e) {
      if (e.target.id) {
        clearInterval(this.timer);
      }
    },
    // 监听移出事件
    listenerOut(e) {
      if (e.target.id) {
        this.runTags();
      }
    },
    // 点击事件
    clickToPage() {},
  },
  mounted() {
    this.runTags();
  },
};
</script>


<style scoped lang="less">
.fontA {
  fill: #06f1ef;
  font-weight: bold;
  background: gray;
  a {
    font-size: 12px;
    fill: #ffffff;
  }
}
.fontA:hover {
  fill: #ffffff;
  cursor: pointer;
}
</style>


