<template>
    <div id="barEcharsTop" style="width:100%;height:100%;"></div>
</template>

<script>
import *as echarts from 'echarts'
export default {
  data() {
    return {
      chartData:[
          { name: '开发部',value: 600},
          { name: '解决方案部',value: 219},
          { name: '营销一部',value: 154},
          { name: '技术部',value: 106},
          { name: '采购部',value: 103},
          { name: '人事部',value: 100},
          { name: '测试部',value: 66},
          { name: 'ui设计部',value: 65},
          { name: '产品管理部',value: 61},
          { name: '开源开发部',value: 58},
        ],
      charts:null
    };
  },
  created() {},
  mounted() {
    this.init();
    //根据浏览器宽度变化改变可视化报表图宽高
    window.addEventListener("resize", () => {
      this.charts.resize();
    });
  },
  methods: {
    init() {
      this.charts = this.$echarts.init(document.getElementById("barEcharsTop"));
      const option = {
    // backgroundColor: '#00265f',
       tooltip: {
          trigger: "axis",
        },
        title: {
          text: '共享量部门TOP10',
          textStyle: {
              fontWeight: 'normal',
              fontSize: 12,
              color: '#06f1ef'
          },
          top: '1.5%',
          left: '1.5%'
        },
        grid: {
          // left: "12%",
          // right: "3%",
          // top: "15%",
          top:'20%',//距上边距
            left:'1%',//距离左边距
            right:'0%',//距离右边距
            bottom:'0%',//距离下边距
            containLabel:true
        },
        xAxis: {
            type: 'category',
            data: this.chartData.map(item=>item.name),
            axisLine: {
              lineStyle: {
                color: "#06f1ef",
              },
            },
            axisLabel: {
              textStyle: {
                  color: "#00c7ff",
              },
              interval: 0,
              rotate:30,	
            },
        },
        yAxis: {
          axisLine: {
            show:true,
            lineStyle: {
              color: "#06f1ef",
            },
          },
          splitLine: {
            show: false
          },
          axisTick: { show: true },
          axisLabel:{show:true},
        },
        series: [{
            type: 'bar',
            data: this.chartData.map(item=>item.value),
            barWidth: 10, //柱子宽度
            itemStyle: {
                normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#008cff'
                    }, {
                        offset: 1,
                        color: '#005193'
                    }]),
                    opacity: 1,
                }
            }
        }]
      };
      this.charts.setOption(option)
    },
  },
};
</script>

<style scoped lang="less">
#mains {
  width: 100%;
  height: 100%;
  #barEcharsTop {
    width: 100%;
    height: 100%;
  }
}
</style>
