<template>
  <div style="width: 100%; height: 100%">
    <div :id="id" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
export default {
  props:{
    text:{
      type:String,
      default:''
    },
    pieList:{
      type:Array,
      default(){
       return [
              { value: 335, name: '医学类型' },
              { value: 310, name: '金融类型' },
              { value: 274, name: '档案类型' },
              { value: 214, name: '标书类型' },
              {value:210,name:'计算机类型'},
              { value: 400, name: '设计搞类型' },
            ]
      }
    }
  },
  data() {
    return {
      id:'leftPie'+Math.random(),
      charts:null
    };
  },
  created() {},
  mounted() {
    this.init();
    //根据浏览器宽度变化改变可视化报表图宽高
    window.addEventListener("resize", () => {
      this.charts.resize();
    });
  },
  methods: {
    init() {
      this.charts = this.$echarts.init(document.getElementById(this.id));
      this.charts.setOption({
        title: {
          text: this.text,
          left: "right",
          textStyle: {
            color: "#06f1ef",
            fontSize: "14px",
          },
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '55%',
            // color: ['#ffbd5d', '#2655ff','#f57884','#817e7e','#9e9c9c','#faca55'],
            center: ['50%', '50%'],
            data: this.pieList.sort(function (a, b) {
              return a.value - b.value;
            }),
            roseType: 'radius',
            label: {
              color: 'rgba(255, 255, 255, 0.3)'
            },
            labelLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.3)'
              },
              smooth: 0.2,
              length: 10,
              length2: 20
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
              return Math.random() * 200;
            },
            itemStyle: {
                normal: {
                    label: {
                        show: true,
                        position: 'outside',
                        color: '#ddd',
                        formatter: function(params) {
                          console.log(params)
                          return  params.name + '\n' + params.value + '个';
                        },
                    }
                }
            },
          }
        ]
      });
    },
  },
};
</script>

<style scoped lang="less">
</style>
