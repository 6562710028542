<template>
  <div style="width: 100%; height: 100%">
    <div :id="id" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
export default {
  props:{
    text:{
      type:String,
      default:''
    },
    pieData:{
      type:Array,
      default:[]
    }
  },
  data() {
    return {
      id:'leftPie'+Math.random(),
      charts:null
    };
  },
  created() {},
  mounted() {
    this.init();
    //根据浏览器宽度变化改变可视化报表图宽高
    window.addEventListener("resize", () => {
      this.charts.resize();
    });
  },
  methods: {
    init() {
      this.charts = this.$echarts.init(document.getElementById(this.id));
      this.charts.setOption({
        title: {
          text: this.text,
          left: "right",
          textStyle: {
            color: "#06f1ef",
            fontSize: "14px",
          },
        },
        legend: {
          orient: "vertical",
          right: "1%",
          bottom: "-1%",
          textStyle: {
            color: "white",
          },
        },
        series: [
          {
            type: "pie",
            radius: ["0%", "80%"],
            color: ['#37a2da', '#2f4554'],
            avoidLabelOverlap: true,
            center: ["38%", "center"],
            label: {
              show: true,
              position: "inside",
              formatter: "{d}%",
              align: "center",
              color: "#06f1ef",
              fontWeight: "bold",
              fontSize: "12px",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "20",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              borderColor: "#ffffff",
              borderWidth: 1,
            },
            data: this.pieData,
          },
        ],
      });
    },
  },
};
</script>

<style scoped lang="less">
</style>
