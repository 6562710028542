<template>
  <el-dialog
    title="预警文档列表"
    :visible.sync="visible"
    :close-on-click-modal="false" 
    >
    <el-table :data="listData" border size="small">
      <el-table-column
          label="文件名">
          <template slot-scope="scope">
            {{scope.row[0]}}
          </template>
        </el-table-column>
        <el-table-column
        align="center"
          label="类型">
          <template slot-scope="scope">
            <span>{{scope.row[1] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="预警内容"
          align="center">
          <template slot-scope="scope">
            <span>{{ scope.row[2] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="预警时间"
          align="center"
          width="160">
          <template slot-scope="scope">
            <span>{{ scope.row[4]|date('yyyy-MM-dd') }}</span>
          </template>
        </el-table-column>
          <el-table-column
          align="center"
          label="预警状态">
          <template slot-scope="scope">
            <span>{{ (scope.row[3].indexOf('未处理')!=-1)?'未处理':'已处理' }}</span>
          </template>
        </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
export default {
  data(){
      return{
          visible:false,
          listData:[],
      }
  },
  methods:{
    show(list,dates){
      console.log(list)
      this.listData = [list.row]
      this.visible = true;
      console.log(this.listData)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog{
background: #1d1b1bbf;
height: 400px;
.el-dialog__title{
  color: #fff;
  font-size: 16px !important;
  font-weight: bold;
}
}
/deep/.el-table{
background: transparent;
color: #fff;
th , tr{
  color: #fff !important;
   background: transparent;
   span {
    color: #fff !important;
   }
}
.el-table__row:hover{
  td {
    background: #66ceebbf;
  }
}
}

</style>