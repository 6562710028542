<template>
  <div id="rightPie" style="width: 100%; height: 100%"></div>
</template>

<script>
export default {
  data() {
    return {
      charts: null, //可视化图表
    };
  },
  created() {},
  mounted() {
    this.init();
    //根据浏览器宽度变化改变可视化报表图宽高
    window.addEventListener("resize", () => {
      this.charts.resize();
    });
  },
  methods: {
    init() {
        this.charts = this.$echarts.init(document.getElementById("rightPie"));
         var data = [
        {
            name: "长城文链存储中心 10000",
            symbol:	'image://'+require('@/assets/img/base64-img.jpg'),
            symbolSize: [239, 219],
            value: [170, 200],
            x: 300,
            y: 400,
            number:203
        },
        {
            name: "开发部 1500",
            x: 400,
            y: 400,
            value: [400, 400],
            symbol:'image://'+require('@/assets/img/base64-img1.jpg'),
            symbolSize:[89,90],
            number:203
        },
        {
          name: "解决方案部 2000",
          x: 400,
          y: 400,
          value: [10, 380],
          symbol:'image://'+require('@/assets/img/base64-img2.jpg'),
          symbolSize:[89,90],
           number:203
        },
        {
          name: "营销一部 500",
          x: 400,
          y: 400,
          value: [10, 10],
          symbol:'image://'+require('@/assets/img/base64-img2.jpg'),
          symbolSize:[89,90],
          number:203
        },
        {
          name: "技术部 500",
          x: 400,
          y: 400,
          value: [60, 140],
          symbol:'image://'+require('@/assets/img/base64-img1.jpg'),
          symbolSize:[89,90],
           number:203
        },
        {
          name: "采购部 500",
          x: 400,
          y: 400,
          value: [300, 340],
          symbol:'image://'+require('@/assets/img/base64-img1.jpg'),
          symbolSize:[89,90],
           number:203
        },
        {
          name: "人事部 100",
          x: 400,
          y: 400,
          value: [400, 140],
          symbol:'image://'+require('@/assets/img/base64-img2.jpg'),
          symbolSize:[89,90],
           number:203
        },
        {
          name: "测试部 1500",
          x: 400,
          y: 400,
          value: [230, 0],
          symbol:'image://'+require('@/assets/img/base64-img1.jpg'),
          symbolSize:[89,90],
           number:203
        },
        {
          name: "ui设计部 400",
          x: 400,
          y: 400,
          value: [280, 40],
          symbol:'image://'+require('@/assets/img/base64-img2.jpg'),
          symbolSize:[89,90],
           number:203
        },
        {
          name: "产品管理部 500",
          x: 400,
          y: 400,
          value: [100, 340],
          symbol:'image://'+require('@/assets/img/base64-img1.jpg'),
          symbolSize:[89,90],
           number:203
        },
        {
          name: "开源开发部 1500",
          x: 400,
          y: 400,
          value: [100, 10],
          symbol:'image://'+require('@/assets/img/base64-img1.jpg'),
          symbolSize:[89,90],
           number:203
        },
      ];

     let  option=({
        backgroundColor: "#000",
        xAxis: {
          show: false,
          type: "value"
        },
        yAxis: {
          show: false,
          type: "value"
        },
        tooltip: {
            show:false
        },
        series: [
          {
            type: "graph",
            zlevel: 5,
            draggable: false,
            coordinateSystem: "cartesian2d", //使用二维的直角坐标系（也称笛卡尔坐标系）
            label: {
              normal: {
                show: true
              }
            },
            data: data,
            links: [
              {
                source: "长城文链存储中心 10000",
                target: "开发部 1500"
              },
              {
                source: "长城文链存储中心 10000",
                target: "解决方案部 2000"
              },
              {
                source: "长城文链存储中心 10000",
                target: "营销一部 500"
              },
              {
                source: "长城文链存储中心 10000",
                target: "技术部 500"
              },
              {
                source: "长城文链存储中心 10000",
                target: "采购部 500"
              },
              {
                source: "长城文链存储中心 10000",
                target: "人事部 100"
              },
              {
                source: "长城文链存储中心 10000",
                target: "测试部 1500"
              },
              {
                source: "长城文链存储中心 10000",
                target: "ui设计部 400"
              },
              {
                source: "长城文链存储中心 10000",
                target: "产品管理部 500"
              },
              {
                source: "长城文链存储中心 10000",
                target: "开源开发部 1500"
              }
              
            ],
            lineStyle: {
              normal: {
                opacity: 1,
                color: "#53B5EA",
                curveness: 0.2,
                width: 2
              }
            }
          },
          {
            type: "lines",
            coordinateSystem: "cartesian2d",
            z: 1,
            zlevel: 2,
            animation: false,
            effect: {
              show: true,
              period: 8,
              trailLength: 0.01,
              symbolSize: 12,
              symbol: "pin",
              loop: true,
              color: "rgba(55,155,255,0.5)"
            },
            lineStyle: {
              normal: {
                color: "#22AC38",
                width: 0,
                curveness: 0.2
              }
            },

            data: [
              {
                coords: [
                  [170, 200],
                  [400, 400]
                ]
              },
              {
                 coords: [
                  [170, 200],
                  [10, 380]
                ]
              },
              {
                 coords: [
                  [170, 200],
                  [10, 10]
                ]
              },
              {
                 coords: [
                  [170, 200],
                  [60, 140]
                ]
              },
              {
                 coords: [
                  [170, 200],
                  [300, 340]
                ]
              },
              {
                 coords: [
                  [170, 200],
                  [400, 140]
                ]
              },
              {
                 coords: [
                  [170, 200],
                  [220, 40]
                ]
              },
              {
                 coords: [
                  [170, 200],
                  [260, 40]
                ]
              },
              {
                 coords: [
                  [170, 200],
                  [100, 340]
                ]
              },
              {
                 coords: [
                  [170, 200],
                  [130, 20]
                ]
              }
            ]
          }
        ]
      });
   
      this.charts.setOption(option);
    },
  },
};
</script>

<style scoped lang="less">
</style>
