
<template>
   <span>
    <dv-scroll-board
      :config="config"
      ref="scrollBoard"
      @click="handleClick"
      style="width: 100%; height: 95%; font-size: 12px"
    >
    
   </dv-scroll-board>
   <warningLoading ref="warningLoading" />
   </span>
    
    
</template>

<script>
import warningLoading from './warningLoading.vue';
export default {
  data() {
    return {
      // config: {
      //   header: [
      //     "文件名",
      //     "敏感词",
      //     "告警时间"
      //     ],
      //     data: [
      //         ["计算机软件著作权登记申请表》_报告管理 敏感词:中国 未处理 2023-02-11"],
      //         ["可信电子文件管理平台_使用手册 敏感词:红色 1100次 2023-02-29"],
      //         ["可信电子文件管理平台_使用手册 阈值(100)2000次 2023-03-31"],
      //         ["海丽伯特漫画 敏感词:毁灭 未处理 2023-03-11"],
      //         ["电子跑步机使用手册 阈值(100)1000次 2023-01-31"],
      //         ["文件管控测试用例 敏感词:bug 已处理  2023-01-01"],
      //         ["个人小二寸照片 阈值(100)1000次  2023-03-10"],
      //         ["计算机软件著作权登记申请照片 阈值(100)2000次  2022-12-09"],
      //         ["民营企业资质注册照片 阈值(100)2000次  2022-11-11"],
      //         ["组织架构图设计文档 敏感词:黄色 已处理  2023-01-19"],
      //     ],
      //       hoverPause: true, //鼠标悬浮时暂停轮播
      //       rowNum: 5, //表格行数
      //       oddRowBGC: "#0f1325", //奇数行背景颜色
      //       evenRowBGC: "#171c33", //偶数行背景颜色
      //       index: false, //是否开启序号
      //       indexHeader: "序号", //序号名称
      //       waitTime: 1000, //翻页时间
      //   },
        config: {
          header: [
          "文件名",
          "告警类型",
          "告警信息",
          "告警状态",
          "告警时间",
          ],
          data: [
						[
              "计算机软件著作权登记申请表",
              "敏感词",
              "中国",
              `<div class="roll-item" style="color:#f56c6c">未处理</div>`,
              "2023-03-24 18:02:22",
            ],
            [
              "报告管理",
              "敏感词",
              "bug",
              `<div class="roll-item" style="color:#f56c6c">未处理</div>`,
              "2023-03-24 18:02:22",   
            ],
            [
              "可信电子文件管理平台_使用手册",
              "打印阈值(100)",
              "452次",
              `<div class="roll-item" style="color:#67c23a">已处理</div>`,
              "2023-03-27 17:46:56",
            ],
            [
              "健康档案文档",
              "敏感词",
              "文档,测试,中共",
              `<div class="roll-item" style="color:#67c23a">已处理</div>`,
              "2023-03-27 14:46:42",
            ],
            [
              "长城链控三方产品对接情况",
              "下载阈值(100)",
              "402次",
              `<div class="roll-item" style="color:#f56c6c">未处理</div>`,
              "2023-03-27 14:35:04", 
            ],
            [
              "《计算机软件著作权登记申请表》_报告管理",
              "下载阈值(100)",
              "200次",
              `<div class="roll-item" style="color:#f56c6c">未处理</div>`,
              "2023-03-23 14:15:26",
              ],
              [
                "电子健康管理文件",
                "访问阈值(100)",
                "150次",
                `<div class="roll-item" style="color:#f56c6c">未处理</div>`,
                "2023-03-23 14:13:13",
              ],
              [
                "慧正流程中台技术方案V8.1",
                "访问阈值(100)",
                "130次",
                `<div class="roll-item" style="color:#67c23a">已处理</div>`,
                "2023-03-27 14:32:46", 
              ],   
              [
                  "体检报告转换结果表",
                  "敏感词",
                  "测试",
                  `<div class="roll-item" style="color:#67c23a">已处理</div>`,
                  "2023-03-24 17:36:10",
              ],
              [
                  "主索引接口文档1.0.1(可信一体机)",
                  "敏感词",
                  "中国,中共",
                  `<div class="roll-item" style="color:#67c23a">已处理</div>`,
                  "2023-03-23 15:15:44",
                
              ],
            ],
            hoverPause: true, //鼠标悬浮时暂停轮播
            rowNum: 8, //表格行数
            headerHeight: 30, //表头高度
            headerBGC: "#77c7c74f", //表头背景颜色
            oddRowBGC: "#0f1325", //奇数行背景颜色
            evenRowBGC: "#171c33", //偶数行背景颜色
            index: false, //是否开启序号
            indexHeader: "序号", //序号名称
            waitTime: 1000, //翻页时间
            align: [
            "center",
            "center",
            "center",
            "center",
            "center",
            "center",
            "center",
            "center",
            ], //对齐方式Array[x,x,x,x]
        },
    };
  },
  components:{warningLoading},
  created() {
    // window.addEventListener("resize", () => {
    //   this.$refs["scrollBoard"].updateRows();
    // });
  },
  mounted() {},
  methods: {
    handleClick(row){
      console.log(row)
      this.$refs['warningLoading'].show(row)
    }
  },
};
</script>

<style lang="less" scoped>
::v-deep .dv-scroll-board .rows .ceil {
  font-size: 13px !important;
}
#table1 {
  width: 100%;
  height: 100%;
  padding: 10px;
  .title {
    display: flex;
    span {
      color: #c3cbde;
      font-size: 15px;

      margin-right: 10px;
    }
  }
}
</style>
