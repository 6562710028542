<template>
  <div id="mains">
    <div id="Echars"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chartData: [{"date":"2023-03-30","size":"2452.780"},{"date":"2023-03-29","size":"1452.280"},{"date":"2023-03-28","size":"2052.280"},{"date":"2023-03-27","size":"1056.027"},{"date":"2023-03-26","size":"2152.027"},{"date":"2023-03-25","size":"1452.027"},{"date":"2023-03-24","size":"2020.027"},{"date":"2023-03-23","size":"1051.883"},{"date":"2023-03-22","size":"2500.425"},{"date":"2023-03-21","size":"442.644"},{"date":"2023-03-20","size":"1041.441"},{"date":"2023-03-19","size":"1441.441"},{"date":"2023-03-18","size":"1941.441"},{"date":"2023-03-17","size":"1518.546"},{"date":"2023-03-16","size":"2396.896"},{"date":"2023-03-15","size":"1389.152"},{"date":"2023-03-14","size":"2653.753"},{"date":"2023-03-13","size":"244.414"},{"date":"2023-03-12","size":"944.414"},{"date":"2023-03-11","size":"1344.414"},{"date":"2023-03-10","size":"2044.414"},{"date":"2023-03-09","size":"1127.764"},{"date":"2023-03-08","size":"1827.764"},{"date":"2023-03-07","size":"1027.424"},{"date":"2023-03-06","size":"560.227"},{"date":"2023-03-05","size":"1327.227"},{"date":"2023-03-04","size":"756.227"},{"date":"2023-03-03","size":"1737.227"},{"date":"2023-03-02","size":"1327.144"},{"date":"2023-03-01","size":"1303.374"}],
      charts:null
    };
  },
  created() {},
  mounted() {
    this.init();
    //根据浏览器宽度变化改变可视化报表图宽高
    window.addEventListener("resize", () => {
      this.charts.resize();
    });
  },
  methods: {
    init() {
      this.charts = this.$echarts.init(document.getElementById("Echars"));
      this.charts.setOption({
        title: {
          text: "文件增长量",
          left: "center",
          top:'20px',
          textStyle: {
            fontSize:'16px',
            color: "#06f1ef",
          },
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "12%",
          right: "3%",
          bottom: "10%",
        },
        xAxis: {
          type: "category",
          data: this.chartData.map(item=>item.date),
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#06f1ef",
            },
          },
        },
        yAxis: {
          type: "value",
          name: '单位：KB',
          axisLine: {
            lineStyle: {
              color: "#06f1ef",
            },
          },

        },
        series: [
          {
            name: "文件大小",
            type: "line",
            data: this.chartData.map(item=>item.size),
            symbolSize: 1,
              emphasis: {
                  itemStyle: {
                      color: "rgba(119,212,245,1)"
                  }
              },
              lineStyle: {
                  color: "rgba(119,212,245,1)"
              },
              areaStyle: {
                  color: "rgba(119,212,245,0.3)"
              },
              itemStyle: {
                  color: "rgba(119,212,245,1)",
              }
          },
        ],
      });
    },
  },
};
</script>

<style scoped lang="less">
#mains {
  width: 100%;
  height: 100%;
  #Echars {
    width: 100%;
    height: 100%;
  }
}
</style>
