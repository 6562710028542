<template>
  <dv-scroll-ranking-board  :config="topList" />
</template>
<script>
export default {
  data() {
    return {
      topList:{
        data:[
          { name: '开发部',value: 238},
          { name: '解决方案部',value: 219},
          { name: '营销一部',value: 154},
          { name: '技术部',value: 106},
          { name: '采购部',value: 103},
          { name: '人事部',value: 100},
          { name: '测试部',value: 66},
          { name: 'ui设计部',value: 65},
          { name: '产品管理部',value: 61},
          { name: '开源开发部',value: 58},
        ]
      }
    }
  },
}
</script>

